var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "md-layout" }, [
    _c(
      "div",
      { staticClass: "md-layout-item" },
      [
        _c(
          "md-card",
          [
            _c(
              "md-card-header",
              { staticClass: "md-card-header-icon md-card-header-green" },
              [
                _c(
                  "div",
                  { staticClass: "card-icon" },
                  [_c("md-icon", [_vm._v("assignment")])],
                  1
                ),
                _c("h4", { staticClass: "title" }, [
                  _vm._v("Paginated Tables"),
                ]),
              ]
            ),
            _c(
              "md-card-content",
              [
                _c(
                  "md-table",
                  {
                    staticClass: "paginated-table table-striped table-hover",
                    attrs: {
                      value: _vm.queriedData,
                      "md-sort": _vm.currentSort,
                      "md-sort-order": _vm.currentSortOrder,
                      "md-sort-fn": _vm.customSort,
                    },
                    on: {
                      "update:mdSort": function ($event) {
                        _vm.currentSort = $event
                      },
                      "update:md-sort": function ($event) {
                        _vm.currentSort = $event
                      },
                      "update:mdSortOrder": function ($event) {
                        _vm.currentSortOrder = $event
                      },
                      "update:md-sort-order": function ($event) {
                        _vm.currentSortOrder = $event
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "md-table-row",
                        fn: function ({ item }) {
                          return _c(
                            "md-table-row",
                            {},
                            [
                              _c(
                                "md-table-cell",
                                {
                                  attrs: {
                                    "md-label": "Name",
                                    "md-sort-by": "name",
                                  },
                                },
                                [_vm._v(_vm._s(item.name))]
                              ),
                              _c(
                                "md-table-cell",
                                {
                                  attrs: {
                                    "md-label": "Email",
                                    "md-sort-by": "email",
                                  },
                                },
                                [_vm._v(_vm._s(item.email))]
                              ),
                              _c(
                                "md-table-cell",
                                { attrs: { "md-label": "Age" } },
                                [_vm._v(_vm._s(item.age))]
                              ),
                              _c(
                                "md-table-cell",
                                { attrs: { "md-label": "Salary" } },
                                [_vm._v(_vm._s(item.salary))]
                              ),
                              _c(
                                "md-table-cell",
                                { attrs: { "md-label": "Actions" } },
                                [
                                  _c(
                                    "md-button",
                                    {
                                      staticClass:
                                        "md-just-icon md-info md-simple",
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.handleLike(item)
                                        },
                                      },
                                    },
                                    [_c("md-icon", [_vm._v("favorite")])],
                                    1
                                  ),
                                  _c(
                                    "md-button",
                                    {
                                      staticClass:
                                        "md-just-icon md-warning md-simple",
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.handleEdit(item)
                                        },
                                      },
                                    },
                                    [_c("md-icon", [_vm._v("dvr")])],
                                    1
                                  ),
                                  _c(
                                    "md-button",
                                    {
                                      staticClass:
                                        "md-just-icon md-danger md-simple",
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.handleDelete(item)
                                        },
                                      },
                                    },
                                    [_c("md-icon", [_vm._v("close")])],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        },
                      },
                    ]),
                  },
                  [
                    _c(
                      "md-table-toolbar",
                      [
                        _c(
                          "md-field",
                          [
                            _c("label", { attrs: { for: "pages" } }, [
                              _vm._v("Per page"),
                            ]),
                            _c(
                              "md-select",
                              {
                                attrs: { name: "pages" },
                                model: {
                                  value: _vm.pagination.perPage,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.pagination, "perPage", $$v)
                                  },
                                  expression: "pagination.perPage",
                                },
                              },
                              _vm._l(
                                _vm.pagination.perPageOptions,
                                function (item) {
                                  return _c(
                                    "md-option",
                                    {
                                      key: item,
                                      attrs: { label: item, value: item },
                                    },
                                    [_vm._v(" " + _vm._s(item) + " ")]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "md-field",
                          [
                            _c("md-input", {
                              staticClass: "mb-3",
                              staticStyle: { width: "200px" },
                              attrs: {
                                type: "search",
                                clearable: "",
                                placeholder: "Search records",
                              },
                              model: {
                                value: _vm.searchQuery,
                                callback: function ($$v) {
                                  _vm.searchQuery = $$v
                                },
                                expression: "searchQuery",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "footer-table md-table" }, [
                  _c("table", [
                    _c("tfoot", [
                      _c(
                        "tr",
                        _vm._l(_vm.footerTable, function (item) {
                          return _c(
                            "th",
                            { key: item.name, staticClass: "md-table-head" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-table-head-container md-ripple md-disabled",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "md-table-head-label" },
                                    [_vm._v(" " + _vm._s(item) + " ")]
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "md-card-actions",
              { attrs: { "md-alignment": "space-between" } },
              [
                _c("div", {}, [
                  _c("p", { staticClass: "card-category" }, [
                    _vm._v(
                      "Showing " +
                        _vm._s(_vm.from + 1) +
                        " to " +
                        _vm._s(_vm.to) +
                        " of " +
                        _vm._s(_vm.total) +
                        " entries"
                    ),
                  ]),
                ]),
                _c("pagination", {
                  staticClass: "pagination-no-border pagination-success",
                  attrs: {
                    "per-page": _vm.pagination.perPage,
                    total: _vm.total,
                  },
                  model: {
                    value: _vm.pagination.currentPage,
                    callback: function ($$v) {
                      _vm.$set(_vm.pagination, "currentPage", $$v)
                    },
                    expression: "pagination.currentPage",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }